<template>
  <div>
    <b-card title="Dziękujemy za złożenie zamówienia">
      <b-card-text>
        <b-container>
          <b-row>
            <b-col>
              <h4>Zamówienie</h4>
              <p><strong>Data zamówienia:</strong> {{ orderCreatedAt }}</p>
              <p><strong>Kwota zamówienia: </strong> {{ order.total }} zł</p>
            </b-col>
            <b-col>
              <h4>Płatność</h4>
              <p><strong>Status:</strong> {{ order.status }}</p>
              <p><strong>Kwota zamówienia brutto: </strong> {{ order.total }} zł</p>
            </b-col>
          </b-row>
        </b-container>
      </b-card-text>
    </b-card>

    <b-card title="Elementy zamówienia">
      <b-card-text>
        <b-table
            responsive="sm"
            :items="order.items"
            :fields="fields"
        />
      </b-card-text>
    </b-card>

  </div>
</template>

<script>
export default {
  name: "SubscriptionSuccess",

}
</script>

<style scoped>

</style>
